<template>
  <v-app>
    <div>
      <v-row class="pl-2">
        <v-col class="pa-5" cols="12" sm="12" md="12">
          <h4>Nhập câu hỏi</h4>
          <CkContent
            validateName="question"
            :content="question.content"
            @getData="getQuestionContent"
          ></CkContent>
        </v-col>
      </v-row>
      <ValidationProvider name="name" rules="required" v-slot="{ errors }">
        <v-row style="margin-left: 0">
          <v-col class="pa-5" cols="3">
            <h4>Chọn từ</h4>
            <template>
              <v-select
                v-model="question.answers[0].content"
                :items="filledWords"
                item-text="content"
                label="Chọn từ"
                dense
                outlined
              ></v-select>
            </template>
          </v-col>
        </v-row>
        <span style="margin-left: 10px; margin-top: -20px; position: absolute" class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
      </ValidationProvider>
      <v-row>
        <v-col>
          <div class="text-center justify-center">
            <div class="my-4">
              <v-btn
                  min-width="80"
                class="ma-2 white--text"
                color="blue-grey"
                @click="$router.go(-1)"
                >Hủy</v-btn
              >
              <v-btn
                v-if="!isUpdate"
                color="primary"
                min-width="80"
                type="submit"
                @click="submitQuestion"
                >Lưu</v-btn
              >
              <v-btn
                min-width="80"
                v-if="isUpdate"
                color="primary"
                type="submit"
                @click="submitQuestion"
                >Cập nhật</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>
<script>
import CkContent from "@/components/ck-content";
import {
  GET_EXERCISE_DETAIL,
  SAVE_EXERCISE_CONTENT,
  UPDATE_EXERCISE_CONTENT,
  GET_FILLED_WORD_LIST,
  GET_QUESTION_DETAIL,
} from "@/store/exercises.module";
import { mapGetters } from "vuex";
export default {
  components: {
    CkContent,
  },
  data: () => ({

    isUpdate: false,
    selected: "",
    answer: {
      content: "",
    },
    answers: [],
  }),
  computed: {
    ...mapGetters({
      exercise: "exerciseDetail",
      filledWords: "filledWordList",
      question: "questionDetail",
    }),
  },
  async created() {
    await this.$store.dispatch(GET_EXERCISE_DETAIL, {
      exercise_slug: this.$route.query.exercise_slug,
    });
    await this.$store.dispatch(GET_FILLED_WORD_LIST, {
      exercise_slug: this.exercise.slug,
    });
    await this.$store.dispatch(GET_QUESTION_DETAIL, {
      question_id: this.$route.query.question_id,
    });
  },
  mounted() {
    if (this.$route.query.question_id) {
      this.isUpdate = true;
    }
  },
  methods: {
    getQuestionContent(value) {
      this.question.content = value;
    },
    submitQuestion() {
      if ( this.question.content ===''){
        alert('điền thông tin đầy đủ')
      }
      else if (this.question.answers[0].content === ''){
        alert('điền thông tin đầy đủ')
      }
      else {
        this.answers.push(this.question.answers[0]);
        if (this.isUpdate) {
          this.$store
              .dispatch(UPDATE_EXERCISE_CONTENT, {
                question: this.question,
                answers: this.answers,
                exercise: this.exercise,
              })
              .then(() => {
                this.$router.go(-1);
              });
        }
        if (!this.isUpdate) {
          this.$store
              .dispatch(SAVE_EXERCISE_CONTENT, {
                question: this.question,
                answers: this.answers,
                exercise: this.exercise,
              })
              .then(() => {
                this.$router.go(-1);
              });
        }
      }
    },
  },
};
</script>